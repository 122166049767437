import Root from '../components/Root';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/fi';
import localeData from 'dayjs/plugin/localeData';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import Script from 'next/script';
import DatadogInitializer from '../utils/DatadogInitializer';
import { customScrollbarStyles } from '../utils/customScrollbarStyles';
import { SSRContext, ssrContextDefault } from '../hooks/useSSRContext';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Overlay from '../components/Overlay';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { NOTIFICATIONS_STATUS_ERROR, NOTIFICATIONS_STATUS_SUCCESS } from '../variables';

// Localize dayjs
dayjs.extend(localeData);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);

if (isMobile) {
    require('../scss/mobile-style.scss');
} else {
    require('../scss/style.scss');
}

const theme = createTheme();

const MyApp = ({ Component, pageProps = {} }) => {
    const [seonSDKStatus, setSeonSDKStatus] = useState(null);
    const props = {
        ...ssrContextDefault,
        ...pageProps
    };

    useEffect(() => {
        import('@datadog/browser-rum')
            .then((module) => {
                new DatadogInitializer(module.datadogRum, props.device?.getUA);
            })
            .catch(() => console.error('Failed to load datadogRum'));
    }, []);

    return (
        <>
            <Script
                src="https://cdn.seondf.com/js/v6/agent.umd.js"
                strategy="afterInteractive"
                onLoad={() => setSeonSDKStatus(NOTIFICATIONS_STATUS_SUCCESS)}
                onError={() => setSeonSDKStatus(NOTIFICATIONS_STATUS_ERROR)}
            />
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <style jsx="true">{customScrollbarStyles}</style>
                    <SSRContext.Provider value={props}>
                        <Root {...props} seonSDKStatus={seonSDKStatus}>
                            {props.device?.isDesktop ? (
                                <Overlay {...props}>
                                    <Component {...props} />
                                </Overlay>
                            ) : (
                                <Component {...props} />
                            )}
                        </Root>
                    </SSRContext.Provider>
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    );
};

export default MyApp;
